import React from "react";

const ChangeView = ({ onViewByChange }) => {
  return (
    <div className="view-icon">
      <select className="custom-select" onChange={(e) => onViewByChange(e)}>
        <option value="card">Card </option>
        <option value="grid">Grid</option>
        <option value="table">Table</option>
      </select>
    </div>
  );
};

export default ChangeView;
