import React, { useState } from 'react'


const PaginationPages = ({  total,next,prev,to,from,disable }) => {
  
 return (
      
        <ul className="pagination">
          <li 
          className="page-item"
        
          >
            <button onClick={()=> prev()} disabled={from == '1'}   className="btn-icon fas fa-chevron-left mr-4"></button>
          </li>
         
          
            <li>
              <span >
            {from} - {to} of  {total}
              </span>
            </li>
   
          <li  className= 'page-item'>
           <button  onClick={() => next()} disabled={to === total}  className="btn-icon fas fa-chevron-right ml-4"></button>
          </li>
        </ul>
     
    );
  };
  
  export default PaginationPages;