import React from "react";

const MetaData = () => {
  return (
    <>
      <section className="meta-data-page">
        <div className="container ">
          <div className="top-info">
            <h3>Metadata : nerdy_map</h3>
            <button className="btn btn-primary"> Return to Map</button>
          </div>
          <div className="info-box">
            <h5>Identification</h5>
            <ul>
              <li>
                <dt>Title</dt>
                <dd>nerdy_map</dd>
              </li>
              <li>
                <dt>License</dt>
                <dd>
                  Not Specified{" "}
                  <a href="/">
                    <i className="fa fa-info-circle"></i>
                  </a>
                </dd>
              </li>
              <li>
                <dt>Publication Date</dt>
                <dd>May 6, 2020, 3:04 p.m.</dd>
              </li>
              <li>
                <dt>Regions</dt>
                <dd>
                  Global, Africa, Central Africa, West Africa, Pacific, Kiribati
                </dd>
              </li>
              <li>
                <dt>Approved</dt>
                <dd> Yes </dd>
              </li>
              <li>
                <dt>Published</dt>
                <dd> Yes </dd>
              </li>
              <li>
                <dt>Featured</dt>
                <dd> No </dd>
              </li>
            </ul>
          </div>
          <div className="info-box">
            <h5>Contact Points</h5>
            <ul>
              <li>
                <dt>Name</dt>
                <dd>normal_user</dd>
              </li>
              <li>
                <dt>email</dt>
                <dd></dd>
              </li>
              <li>
                <dt>Position</dt>
                <dd>None</dd>
              </li>
              <li>
                <dt>Organization</dt>
                <dd>None</dd>
              </li>
              <li>
                <dt>Location</dt>
                <dd></dd>
              </li>
              <li>
                <dt>Voice</dt>
                <dd>None</dd>
              </li>
              <li>
                <dt>Fax</dt>
                <dd>None</dd>
              </li>
            </ul>
          </div>
          <div className="info-box">
            <h5>Owner</h5>
            <ul>
              <li>
                <dt>Name</dt>
                <dd>normal_user</dd>
              </li>
              <li>
                <dt>email</dt>
                <dd></dd>
              </li>
              <li>
                <dt>Position</dt>
                <dd>None</dd>
              </li>
              <li>
                <dt>Organization</dt>
                <dd>None</dd>
              </li>
              <li>
                <dt>Location</dt>
                <dd></dd>
              </li>
              <li>
                <dt>Voice</dt>
                <dd>None</dd>
              </li>
              <li>
                <dt>Fax</dt>
                <dd>None</dd>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default MetaData;
