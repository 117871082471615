import React from "react";
import Moment from "react-moment";
import {Link} from 'react-router-dom'

const CardView = ({ item }) => {
  return (
    <div className="card" key={item.id}>
      <img src={item.thumbnail_url} className="card-img-top" alt="" />
      <div className="card-body">
        <h5 className="card-title"><Link to={`/maps/${item.id}`}>{item.title}</Link></h5>
        <span className="text-muted">{item.supplemental_information}</span>
        <ul className="description-links">
          <li>
            <i className="fas fa-lock"></i> Private
          </li>
          <li>
            <i className="fas fa-calendar-alt"></i> Updated{" "}
            <Moment fromNow>{item.date}</Moment>
          </li>
          <li>
            <i className="fas fa-tag"></i> No tags
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CardView;