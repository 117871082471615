import React, { Component } from "react";

class LogIn extends Component {
  render() {
    return (
      <>
        <div className="login">
          <h3 className="logo-title">
            <img src="favicon.png" alt="" /> Cartoview
          </h3>
          <div className="d-lg-flex half">
            <div
              className="bg order-1 order-md-2"
              style={{ backgroundImage: "url('login.jpg')" }}
            ></div>
            <div className="contents order-2 order-md-1">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-7">
                    <div className="mb-4">
                      <h3>Log In</h3>
                      <p className="mb-4">
                        Lorem ipsum dolor sit amet elit. Sapiente sit aut eos
                        consectetur adipisicing.
                      </p>
                    </div>
                    <form>
                      <div className="form-group first">
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Name"
                        />
                      </div>
                      <div className="form-group last mb-3">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                        />
                      </div>

                      <div className="d-flex mb-5 align-items-center">
                        <label className="control control--checkbox mb-0">
                          <span className="caption">Remember me</span>
                          <input type="checkbox" />
                          <div className="control__indicator"></div>
                        </label>
                        <span className="ml-auto">
                          <a href="/" className="forgot-pass">
                            Forgot Password
                          </a>
                        </span>
                      </div>

                      <input
                        type="submit"
                        value="Log In"
                        className="btn btn-block btn-primary"
                      />

                      <span className="d-block text-center my-4 text-muted">
                        &mdash; or &mdash;
                      </span>

                      <div className="social-login">
                        <a
                          href="/"
                          className="google btn d-flex justify-content-center align-items-center"
                        >
                          <span className="icon-google mr-3"></span> Login with
                          Google
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LogIn;