import React, { useState } from 'react';
import Tabs from './Tabs';

const PopUp = ({closeModal}) => {
  const [show,setShow] =useState(false)

const handleToggle=()=>{
  setShow(!show)
}

  return (
  
  <>
<div id="modal-content">
<div className="top-part">
    <div className="top-content">
     
        <div className="data-icon">
          <img src="add_data.png" alt="data-icon"/>
        </div>
        <strong>add datasets </strong>
        <span>select your datasets </span>
       
      </div>
      <div className="close-icon" onClick={()=> closeModal()} >
        <i className="fas fa-times"></i>
        </div>
  </div>
  <div className="middle-part">
    <div className="container">
    <div className="links">
      <div className="search-box">
       <button type="button" className="btn text-primary" onClick={()=>handleToggle()}>search</button>
       <input type="text" className={show ?'input-search show':'input-search'} placeholder=""/>
      </div>
      <div className="tabs-content">
      <Tabs>
        <div label="upload">
          See ya later, <em>Alligator</em>!
        </div>
        <div label="connect">
          After &apos;while, <em>Crocodile</em>!
        </div>
        <div label="Sarcosuchus">
          Nothing to see here, this tab is <em>extinct</em>!
        </div>
      </Tabs>
      </div>
      <div className="link">
      <button type="button" className="btn text-primary">create empty dataset</button>

      </div>
    </div>
    <div className="bottom-part">
      <div className="left-part">
        <input type="checkbox" className=""/>

        <label>automatically guess data types and content on import.</label>
      </div>
      <div className="right-part">
      <div className="right-icon">

      </div>
    <button type="button" className="btn text-primary">create </button>

    </div>
    </div>
   
    </div>
  
  </div>


 
</div>



  
 
    
  </>
  )
}

export default PopUp

