import axios from "axios"
import {urls} from './urls'
const layersUrl = urls.layersListApi;

export const fetchExchangeLayers = async (params) => {
    const result = await axios.get(layersUrl, { params });
  
    return {
      layers: result.data.objects,
      meta: result.data.meta,
    };
  };