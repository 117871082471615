import React, { useState, useEffect } from "react";
import SortingData from "./SortingData";
import PaginationPages from "./paginationPages";
import LoadingMessage from "./LoadingMessage";
import Fillter from "./Fillter";
import ChangeView from "./ChangeView";
import LimitControl from "./limitcontrol";
import CardView from "./views/card";
import GridView from "./views/grid";
import TableView from "./views/table";
import {fetchExchangeMaps} from '../../services/maps'
import {initialParams} from '../../services/urls'




function Maps() {
  const [loading, setLoading] = useState(false);
  const [maps, setMaps] = useState([]);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState(initialParams);
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(initialParams.limit);
  const [orderBy, setOrderBy] = useState("-date");
  const [view, setView] = useState("card");

  useEffect(() => {
    setLoading(true);
    const setItems = async () => {
      const { maps, meta } = await fetchExchangeMaps(params);
      setMaps(maps);
      setMeta(meta);
      setLoading(false);
    };
    setItems();
  }, [params]);

 
  const onNext = (_) => {
    setParams({
      ...params,
      offset: meta.offset + meta.limit,
    });
  };
  const onPrev = (_) => {
    setParams({
      ...params,
      offset: meta.offset - meta.limit,
    });
  };
  const onLimitChange = (e) => {
    setLimit(e.target.value);
    setParams({
      ...params,
      limit: e.target.value,
      offset: 0,
    });
  };
  const onOrderByChange = (e) => {
    setOrderBy(e.target.value);
    setParams({
      ...params,
      order_by: e.target.value,
    });
  };
  const onViewByChange = (e) => {
    setView(e.target.value);
  };
 const definitionView = () => {
    switch (view) {
      case "card":
        return (
          <>
            <PaginationPages
              total={meta.total_count}
              next={onNext}
              prev={onPrev}
              from={meta.offset + 1}
              to={meta.offset + maps.length}
            />
            <div className="maps">
              {maps.map((item, i) => (
                <CardView key={i} item={item} />
              ))}
            </div>
          </>
        );
      case "grid":
        return (
          <>
            <PaginationPages
              total={meta.total_count}
              next={onNext}
              prev={onPrev}
              from={meta.offset + 1}
              to={meta.offset + maps.length}
            />
            <div className="maps">
              {maps.map((item, i) => (
                <GridView key={i} item={item} />
              ))}
            </div>
          </>
        );
      case "table":
        return <TableView data={maps} />;
      default:
        break;
    }
  };


  return (
    <div className="container mt-100">
      <div className="row">
        <div className="col-md-3 title">
          <h3>
            <i className="fas fa-map"></i>

            <span>Your Maps</span>
          </h3>
        </div>
        <div className="offset-md-3 col-md-6  flex-wrap d-flex align-items-center justify-content-between">
          <Fillter />
          <LimitControl onLimitChange={onLimitChange} />
          <SortingData onOrderByChange={onOrderByChange} />
          <ChangeView onViewByChange={onViewByChange} />
          <button type="button" className="btn btn-primary">
            New Map
          </button>
        </div>
      </div>

      {loading ? (
        <>
          <LoadingMessage content={"maps"} />
        </>
      ) :  definitionView()}
    </div>
  );
}
export default Maps;
