import styled from 'styled-components';

import { variant } from 'styled-system';

const Button = styled('button')(
  {
    display: 'block',
    fontWeight: '400',
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    borderRadius:'0.25rem',
    border: '1px solid transparent' 
  },

  variant({
    variants: {
      default: {
        color: '#0879FA',
        bg: 'white',
      },
      primary: {
        color: 'white',
        bg: '#2c689c',
      },
      secondary: {
        color: 'white',
        bg: '#6C757D',
      },
      warning: {
        color: 'white',
        bg: '#FEC134',
      },
      info: {
        color: 'white',
        bg: '#31A2B8',
      },
    },
  }),
);

export default Button;
