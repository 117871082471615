import React, { useState, useEffect } from 'react';
import PaginationPages from './paginationPages';
import SortingData from './SortingData';
import Fillter from './Fillter';
import LoadingMessage from './LoadingMessage';
import LimitControl from './limitcontrol';
import Modal from 'react-modal';
import PopUp from './Popup';
import ChangeView from './ChangeView';
import CardView from "./views/card";
import GridView from "./views/grid";
import TableView from "./views/table";
import {fetchExchangeLayers} from '../../services/layers'
//import {initialParams} from '../../services/urls'

export const initialParams = {
  limit: 20,
  offset: 0,

};

function DataSet() {

  const [loading, setLoading] = useState(false);
  const [layers, setLayers] = useState([]);
  const [meta, setMeta] = useState({});
  const [params, setParams] = useState(initialParams);
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(initialParams.limit);
  const [orderBy, setOrderBy] = useState("-date");
  const [view, setView] = useState("card");



  useEffect(() => {
    setLoading(true)
    const setItems = async () => {
      const { layers, meta } = await fetchExchangeLayers(params);
      setLayers(layers);
      setMeta(meta);
      setLoading(false);
    };
    setItems();
    
},[params]);



const onNext = (_) => {
  setParams({
    ...params,
    offset: meta.offset + meta.limit,
  });
 
};
const onPrev = (_) => {
  setParams({
    ...params,
    offset: meta.offset - meta.limit,
  });
};
const onLimitChange = (e) => {
  setLimit(e.target.value);
  setParams({
    ...params,
    limit: e.target.value,
    offset: 0,
  });
};
const onOrderByChange = (e) => {
  setOrderBy(e.target.value);
  setParams({
    ...params,
    order_by: e.target.value,
  });
};

const onViewByChange = (e) => {
  setView(e.target.value);
};


const [modalIsOpen,setIsOpen] = useState(false);

    
    


    Modal.setAppElement('body')
    
    function openModal() {
      setIsOpen(true);
      document.body.style.overflow = 'hidden';
    }
   
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    }
   
    function closeModal(){
      setIsOpen(false);
    }


  
    const customStyles = {
        rows: {
          style: {
            minHeight: '72px',
            width:'100%'

           
          }
        },
        headCells: {
          style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
          },
        },
        cells: {
          style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px', 
          },
        },
        content : {
            top                   :'0',
            left                  : '0',
            right                 : '0',
            height                : '100%', 
            padding               : '0',
          }
       
      };
      const definitionView = () => {
        switch (view) {
          case "card":
            return (
              <>
                <PaginationPages
                  total={meta.total_count}
                  next={onNext}
                  prev={onPrev}
                  from={meta.offset + 1}
                  to={meta.offset + layers.length}
                />
                <div className="maps">
                  {layers.map((item, i) => (
                    <CardView key={i} item={item} />
                  ))}
                </div>
              </>
            );
          case "grid":
            return (
              <>
                <PaginationPages
                  total={meta.total_count}
                  next={onNext}
                  prev={onPrev}
                  from={meta.offset + 1}
                  to={meta.offset + layers.length}
                />
                <div className="maps">
                  {layers.map((item, i) => (
                    <GridView key={i} item={item} />
                  ))}
                </div>
              </>
            );
          case "table":
            return <TableView data={layers} />;
          default:
            break;
        }
      };
    return (
     <>    
      <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      
      style={customStyles}
      >
        <PopUp onRequestClose={closeModal}/></Modal>
   
      <div className="container mt-100">
          <div className="row">
              <div className="col-md-4 title">
                  <h3>
                      <i className="fas fa-database"></i>

                      <span>Your Datasets</span>
                  </h3>
              </div>
              <div className="offset-md-2 col-md-6 flex-wrap d-flex align-items-center justify-content-between">
                  
                  <Fillter/>
                  <LimitControl onLimitChange={onLimitChange}/>
                  <SortingData onOrderByChange={onOrderByChange}/>
                  <ChangeView onViewByChange={onViewByChange} />
                  <button type="button" className="btn btn-primary"  onClick={openModal} >
                      New Dataset
                  </button>
              </div>
          </div>
      
          {loading  ?  <>  
                 <LoadingMessage content={'data'}/>
              </>
              :
              definitionView()
          }



      </div >
     </>
      
    );
  }
export default DataSet;

