import axios from "axios"
import {urls} from './urls'
const apiUrl = urls.listApi;

export const fetchAllData = async (params) => {
    const result = await axios.get(apiUrl, { params });
    return {
      api: result.data.objects,
      meta: result.data.meta,
    };
  };