import React, { Component } from 'react'

import Maps from '../components/layout/Maps'
import DataSet from '../components/layout/DataSet'


class Home extends Component {

    render() {
      
        return (
            <>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2">
                                <div className='user-wellcome'>
                                    <h4>hello,<span>sir</span> </h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='links-popup'>
                                    <button type="button" className="btn text-primary" data-toggle="modal" data-target="#exampleModal">
                                        get started
                                    </button>
                                    <button type="button" className="btn text-primary" data-toggle="modal" data-target="#exampleModal">
                                        new map
                                    </button>
                                    <button type="button" className="btn  text-primary " data-toggle="modal" data-target="#exampleModal">
                                        new dataset
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-2 offset-md-4 text-right">
                                <button type="button" className="btn btn-primary" >
                                    Upgrade Now
                                </button>
                            </div>
                        </div>


                    </div>
                </section>
                <section>
                    <Maps />
                </section>
                <section >
                    <DataSet />
                </section>


            </>
        )
    }
}

export default Home
