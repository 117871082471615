import React, {useState, Fragment } from 'react';
import { Link } from 'react-router-dom'
import SearchBox from './searchbox';



const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleNavbarCollapse = () => {
      setIsOpen(!isOpen);
    };
    return (
        <Fragment>

            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <button className="navbar-toggler" onClick={toggleNavbarCollapse} >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" style={{display: `${isOpen ? 'block' : ''}`}}  id="navbarTogglerDemo02">
                        <ul className="navbar-nav ">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/">
                                    <i className="fas fa-home"></i> Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/maps">
                                    <i className="far fa-map"></i> Maps
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/data">
                                    <i className="fas fa-database"></i> Data
                                </Link>
                            </li>

                        </ul>
                        <div className="right-part">
                            <div className="collect-icon">

                                <div className="dropdown">
                                    <button className="fas fa-th  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                    </button>
                                    <div className="dropdown-menu  apps-drop" aria-labelledby="dropdownMenuButton">
                                        <ul className="apps-icons">
                                            <li>
                                                <i className="fas fa-home"></i>
                                                <span className="">home</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-search-location"></i>
                                                <span className="">search</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-map-marked-alt"></i>
                                                <span className="">maps</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-layer-group"></i>
                                                <span className="">layers</span>
                                            </li>
                                            <li>
                                                <i className="far fa-copy"></i>
                                                <span className="">docs</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-user-cog"></i>
                                                <span className="">manage</span>
                                            </li>
                                        </ul>
                                        <ul className="apps-icons">
                                            <li>
                                                <i className="fas fa-home"></i>
                                                <span className="">home</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-search-location"></i>
                                                <span className="">search</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-map-marked-alt"></i>
                                                <span className="">maps</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-layer-group"></i>
                                                <span className="">layers</span>
                                            </li>
                                            <li>
                                                <i className="far fa-copy"></i>
                                                <span className="">docs</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-user-cog"></i>
                                                <span className="">manage</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                           <SearchBox/>
                            <div className="dropdown ">
                                <button type="button" className="btn btn-secondary dropdown-toggle" id="dropdownMenuOffset" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="10,20">
                                </button>
                                <div className="dropdown-menu profile-drop" aria-labelledby="dropdownMenuOffset">
                                    <div className="user-info">
                                        <div className="img-user">
                                            <img src="defualt.png" alt="user-img" />
                                        </div>
                                        <div className="email-user">
                                            <span >Taher-elshoker</span>
                                            <address>taher-elshoker@cartologic.com</address>
                                        </div>
                                    </div>
                                    <ul className="help-links">
                                        <li><i className="fas fa-cogs"></i><Link to="/profile">setting</Link></li>
                                        <li><i className="fas fa-key"></i><Link to="/developer">api keys</Link></li>
                                        <li><i className="fas fa-user"></i><Link to="">public profile</Link></li>
                                        <li><i className="fas fa-bell"></i><Link to="/notification">notification</Link></li>
                                    </ul>
                                    <div className="log-out">
                                        <a href="/">log out</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </nav>

        </Fragment>
    )

}

export default Navbar;