import React, { Component, PropTypes } from 'react'
import ProfileNavbar from '../components/layout/profile/ProfileNavbar'
class Profile extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount () {

    }

    componentDidMount () {

    }

    componentWillReceiveProps (nextProps) {

    }

    shouldComponentUpdate (nextProps, nextState) {

    }

    componentWillUpdate (nextProps, nextState) {

    }

    componentDidUpdate (prevProps, prevState) {

    }

    componentWillUnmount () {

    }

    render () {
        return (
            <div className="container">
                <div className="profile-page">
                    <ProfileNavbar/>
                    <div className="profile-content">
                        <div className="title-content">
                            <h4>your profile information</h4>
                        </div>
                        <div className="profile-img">
                            <div className="user-img">
                              
                                <img src="defualt.png"  alt="user-image"/>
                            </div>
                            <button type="button" className="btn change-img" >change image </button>

                        </div>
                        <div className="profile-form"></div>
        
                    </div>
                </div>
            </div>
             
        )
    }
}



export default Profile