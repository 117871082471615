import React, { Component, useState, useEffect } from "react";
import { useParams,withRouter,useLocation } from "react-router-dom";
import { fetchAllData } from "../services/data";
import { initialParams } from "../services/urls";

import GridView from "../components/layout/views/grid";

const SearchResult = () => {
  const [list, setList] = useState([]);
  const [params, setParams] = useState(initialParams);

  const { query } = useParams();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryString = useQuery();

   useEffect(() => {
    const setItems = async () => {
      const { api } = await fetchAllData(params);
      setList(api);
      setParams({
        ...params,
        title__icontains:queryString.get("query"),
      });
    };
    setItems();
  }, []); 
  
console.log( queryString.get("query"))
  return (
    <>
      <section className="info-result">
        <div className="container">
          <h3>
            {list.length} results for "{queryString.get("query")}"
          </h3>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="maps">
            {list.map((item) => (
              <GridView key={item.id} item={item} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(SearchResult);