import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Navbar from '../components/layout/navbar'
import Home from '../screens/Home'
import MapSection from '../screens/MapSection'
import DataSection from '../screens/DataSection'
import Profile from '../screens/Profile'
import Account from '../screens/Account'
import Billing from '../screens/Billing'
import DeveloperSetting from '../screens/DeveloperSettings'
import Notification from '../screens/Notification'
import SearchResult from '../screens/SearchResult'
import SingleItem from '../screens/SingleItem'
import LogIn from '../screens/Login'
import MetaData from '../screens/MetaDataDetails'


export default function AppRoute() {
    
const LoginContainer = () => (
      <Route exact path="/login" component={LogIn} />
)
  
  
 const DefaultContainer = () => (
     <>
       <Navbar/>
        <Route exact path='/' component={Home} />
        <Route exact path='/maps' component={MapSection} />
        <Route exact path='/data' component={DataSection} />
        <Route exact path='/profile' component={Profile} />
        <Route exact path='/account' component={Account} />
        <Route exact path='/billing' component={Billing} />
        <Route exact path='/developer' component={DeveloperSetting} />
        <Route exact path='/notification' component={Notification} />
        <Route exact path='/search'><SearchResult/></Route>
        <Route  path='/maps/:id'  component={SingleItem}/>
        <Route path='/metadata' component={MetaData}/>
        <Route  path='/layers/:id'  component={SingleItem} />

    </>
   )
    return (
        <Router>
             <Switch>               
             <Route exact path="/(login)" component={LoginContainer}/>
             <Route component={DefaultContainer}/>

            </Switch>
        </Router>

    )
} 
