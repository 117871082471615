import React from 'react'
import { Link } from 'react-router-dom'

const ProfileNavbar = () => {
    return (
        <div className="left-side">
            <ul>
                <li className="left-lnk" >
                    <Link to='/profile' className="">profile</Link>
                </li>
                <li className="left-lnk" >
                    <Link to='/account' className="">account</Link>
                </li>
                <li className="left-lnk" >
                    <Link to='' className="">connected app</Link>
                </li>
                <li className="left-lnk" >
                    <Link to='billing' className="">billing</Link>
                </li>
                <li className="left-lnk" >
                    <Link to='developer' className="">developer settings</Link>
                </li>
            </ul>
        </div>
    )
}

export default ProfileNavbar