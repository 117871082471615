import React, { Component } from 'react'
import Maps from '../components/layout/Maps'


class MapSection extends Component {
    render() {
        return (
            <>
                
                <Maps/>

            </>
        )
    }
}

export default MapSection