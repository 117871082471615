import React, { Component, PropTypes } from 'react'
import ProfileNavbar from '../components/layout/profile/ProfileNavbar'

class Account extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount () {

    }

    componentDidMount () {

    }

    componentWillReceiveProps (nextProps) {

    }

    shouldComponentUpdate (nextProps, nextState) {

    }

    componentWillUpdate (nextProps, nextState) {

    }

    componentDidUpdate (prevProps, prevState) {

    }

    componentWillUnmount () {

    }

    render () {
        return (
            <div className="container">
            <div className="profile-page">
                <ProfileNavbar/>
                <div className="profile-content">
                    <div className="title-content">
                        <h4>your account information</h4>
                    </div>
                  
    
                </div>
            </div>
        </div>
         
        )
    }
}



export default Account