import React from 'react'

const LoadingMessage = ({content}) => {
    return (
        
             <div className="message-loading">
                <div className="loading-icon">
                    <i className="fas fa-spinner  fa-spin"></i>
                </div>
                <div className="message-content">
                   Loading ....
                </div>
             </div>
        
    )
}

export default LoadingMessage