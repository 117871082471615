import React from "react";
import { Link } from "react-router-dom";

const DownloadPopup = ({ handelClose }) => {
  return (
    <>
      <div className="modal-header pt-2 pb-2">
        <h5 className="modal-title">Download Map</h5>
        <button type="button" className="close" onClick={(e) => handelClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pt-2 pb-2">
        <ul>
          <li>
            <Link to="/">Thumbnail</Link>
          </li>
          <li>
            <Link to="/">Remote Thumbnail</Link>
          </li>
        </ul>
      </div>
      <div className="modal-footer pt-2 pb-2 ">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={(e) => handelClose()}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default DownloadPopup;
