import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DownloadPopup from "../components/layout/Modals/DownLoadPopup";
import Modal from "react-modal";
import {CustomBtn} from '.././styles/mainStyle'
const SingleItem = () => {
  const [showModal, setShowModal] = useState(false);
  Modal.setAppElement("body");

  function openModal() {
    setShowModal(true);
    document.body.style.overflow = "hidden";
  }

  function closeModal() {
    setShowModal(false);
  }
  const customStyle = {
    content: {
      top: "20px",
      left: "0",
      right: "0",
      height: "190px",
      width: "600px",
      padding: "0",
      margin: "0 auto",
    },
  };
  return (
    <>
      <Modal isOpen={showModal} style={customStyle} contentLabel="Download Map">
        <DownloadPopup handelClose={closeModal} />
      </Modal>
      <section>
        <div className="container">
          <div className="page-header">
            <h2 className="page-title">Demo Project</h2>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div id="embedded_map">
                <img src="../map-img.png" width="100%" height="100%" alt="" />
              </div>
              <div className="mt-3">
                <Tabs>
                  <TabList>
                    <Tab>
                      <i className="fas fa-info-circle pr-2 pl-1"></i>Info
                    </Tab>
                    <Tab>
                      <i className="fas fa-share pr-2 pl-1"></i>Share
                    </Tab>
                    <Tab>
                      <i className="fas fa-star  pr-2 pl-1"></i>Ratings
                    </Tab>
                    <Tab>
                      <i className="far fa-comment pr-2 pl-1"></i>comments
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <ul className="ul-horizontal">
                      <li>
                        <dt>Title</dt>
                        <dd>Demo Project</dd>
                      </li>
                      <li>
                        <dt>License</dt>
                        <dd>
                          Not Specified{" "}
                          <a href="#license-more-above">
                            <i className="fa fa-info-circle"></i>
                          </a>
                        </dd>
                        <dd className="license-more-above collapse">
                          + The original author did not specify a license.
                        </dd>
                      </li>
                      <li>
                        <dt>Publication Date</dt>
                        <dd>March 27, 2021, 11:57 a.m.</dd>
                      </li>
                      <li>
                        <dt>Regions</dt>
                        <dd>
                          <a href="/">Global</a>
                        </dd>
                      </li>
                      <li>
                        <dt>Responsible</dt>
                        <dd>
                          <a href="/">cartologic@gmail.com</a>
                        </dd>
                      </li>
                    </ul>
                    <dl className="dl-horizontal">
                      <dt>
                        <a href="#more">More info</a>
                      </dt>
                    </dl>
                    <dl className="dl-horizontal">
                      <div className="more collapse">
                        <dt>Language</dt>
                        <dd>English</dd>
                        <dt>Supplemental Information</dt>
                        <dd>No information provided</dd>
                      </div>
                    </dl>
                    <p>
                      <a href="/capabilities/map/1124/">
                        <i className="fa fa-map"></i> Map layers WMS
                        GetCapabilities document
                      </a>
                    </p>
                  </TabPanel>
                  <TabPanel>
                    <header>
                      <h3>Share This Map </h3>
                    </header>
                    <div>
                      <ul className="social">
                        <li>
                          <a href="/" className="email">
                            Email
                          </a>
                        </li>
                        <li>
                          <a href="/" className="fb">
                            Facebook
                          </a>
                        </li>
                        <li>
                          <a href="/" className="tw">
                            Twitter
                          </a>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <h3>Average Rating</h3>
                  </TabPanel>
                  <TabPanel>
                    <header>
                      <h3>
                        Comments <span className="total">(0 total)</span>
                      </h3>
                    </header>
                    <div className="clearfix">
                      <p className="pull-right">Log in to add a comment</p>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="col-md-4">
              <ul className="list-group">
                <li className="list-group-item">
                  <CustomBtn variant="primary" onClick={(e) => openModal()}>
                   Download Map
                  </CustomBtn>
                </li>

                <li className="list-group-item">
                  <CustomBtn variant="primary" onClick={(e) => openModal()}>
                    Metadata Detail
                  </CustomBtn>
                </li>

                <li className="list-group-item">
                  <Link
                    to="/"
                    className="btn btn-outline-secondary btn-md btn-block"
                  >
                    View Map
                  </Link>
                </li>

                <li className="list-group-item flex-column">
                  <h4 className="list-group-item-heading">Map Layers</h4>
                  <p>This map uses the following layers:</p>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/layers/geonode:jamoat_db">jamoat_db</a>
                    </li>
                  </ul>
                </li>

                <li className="list-group-item flex-column">
                  <h4>Copy this map</h4>
                  <p>Duplicate this map and modify it for your own purposes</p>
                  <a
                    href="/maps/new?copy=1124"
                    className="btn btn-default btn-md btn-block"
                  >
                    Create a New Map
                  </a>
                </li>

                <li className="list-group-item flex-column">
                  <h4>About</h4>

                  <p>Responsible, Point of Contact, Metadata Author</p>

                  <section className="last">
                    <div className="avatar span1 pull-left">
                      <a href="/people/profile/solankish66@gmail.com/">
                        <img
                          src="https://www.gravatar.com/avatar/c9cc166455a16362be9660f985ead0ad/?s=40"
                          width="40"
                          alt="solankish66@gmail.com"
                        />
                      </a>
                    </div>

                    <div>
                      <div>
                        <a href="/people/profile/solankish66@gmail.com/">
                          solankish66@gmail.com
                        </a>
                      </div>
                      <div className="details">
                        <div className="meta">
                          <p>No Group</p>
                        </div>
                      </div>
                    </div>
                  </section>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleItem;
