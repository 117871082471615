import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme'
import 'react-tabs/style/react-tabs.css';
import './App.css';

import Approute from './router/Approute'
function App() {
  return (
    <>
    <ThemeProvider theme={theme}>
    < Approute />

    </ThemeProvider>
    </>

  );
}

export default App;
