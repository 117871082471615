export const urls = {
  layersListApi: "https://summit2020.cartoview.net/api/layers/",
  mapsListApi:"https://summit2020.cartoview.net/api/maps/",
  listApi:"https://summit2020.cartoview.net/api/base/",
};

export const initialParams = {
    limit: 100,
    offset: 0,
  
};
