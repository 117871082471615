import React, { Component } from 'react'
import DataSet from '../components/layout/DataSet'

class DataSection extends Component {

    render() {
        return (
            <>

                <DataSet/>

            </>
        )
    }
}

export default DataSection