import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchAllData } from "../../services/data";
import { initialParams } from "../../services/urls";

const SearchBox = () => {
  const [list, setList] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [meta, setMeta] = useState({});
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const setItems = async () => {
      const { api, meta } = await fetchAllData(params);
      setMeta(meta);
      setList(api);
    };
    setItems();
  }, [params]);

  useEffect(() => {
    handleShowAutoBox();
  }, [query]);

  const handleShowAutoBox = () => {
    if (query) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
    setShow(true);
    setParams({
      ...params,
      title__icontains: e.target.value,
    });
  };
  const handleClick=()=>{
    setQuery("")
  }
  return (
    <>
      <form className="form-inline my-2 my-lg-0">
        <input
          className="form-control mr-sm-2"
          type="search"
          placeholder="Search"
          value={query}
          onChange={handleChange}
        />
      </form>
      <ul
        className="auto-complete-box"
        style={{ display: show ? "block" : "none" }}
      >
        <li onClick={(e)=> handleClick()}>
          {" "}
          <i className="fas fa-search"></i>{" "}
          <Link to={{
      pathname: '/search',
      search: `?query=${query}`,
     
    }}>  {query} - {list.length} result{" "} </Link> 
        </li>
        {list.map((item) => (
          <li key={item.id} onClick={(e)=> handleClick()}>
            {" "}
            <Link to={`/maps/${item.id}`}> {item.title}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SearchBox;
