import React from 'react'

const LimitControl = ({onLimitChange}) => {
    return (
        <select className="custom-select"  onChange={(e) => onLimitChange(e)}>
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="100">100</option>
           

        </select>
    )
}

export default LimitControl