import React, { useState, useEffect } from 'react';
import Select from 'react-select';
 


function SortingData({onOrderByChange}){

      return(
        <select className="custom-select"  onChange={(e) => onOrderByChange(e)}>
          <option value="-date">Most Recent</option>
          <option value="date">Less Recent</option>
          <option value="title">A-Z</option>
          <option value="-title">Z-A</option>
          <option value="-popular_count">Most Popular</option>

    </select>
      )
}
export default SortingData
