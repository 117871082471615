import React, { Component, PropTypes } from 'react'
import  LoadingMessage  from '../components/layout/LoadingMessage'

class Notification extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount () {

    }

    componentDidMount () {

    }

    componentWillReceiveProps (nextProps) {

    }

    shouldComponentUpdate (nextProps, nextState) {

    }

    componentWillUpdate (nextProps, nextState) {

    }

    componentDidUpdate (prevProps, prevState) {

    }

    componentWillUnmount () {

    }

    render () {
        return (
            <div className="container mt-100">
                 <div className="row">
                    <div className="col-md-3 title">
                        <h3>
                        <i className="fas fa-bell"></i>
                            <span>Notification</span>
                        </h3>
                    </div>
                </div>

                <LoadingMessage content={'notification'}/>
            </div>
        )
    }
}



export default Notification