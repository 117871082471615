import React from 'react'
import Moment from 'react-moment';
import DataTable from 'react-data-table-component'

const columns = [
    {
        name: "",
        cell: row => <div className=""><img className="img-item" src={row.thumbnail_url} alt="row.title"/></div>
    },
    {
        name: "Title",
        selector: "title",
        sortable: true,
        cell: row =><div title={row.title}>{row.title}</div>

    },
    {
        name: "Modified",
        selector: "date",
        sortable: true,
        cell: row =><div className="time-creation"> <i className="fas fa-ellipsis-h"></i> <Moment  format="DD/MM/YYYY">{row.date}</Moment></div>


    },
    {
        name: "Owner",
        selector: "owner__username",
        sortable: true,
        cell: row => <div className="owner"><i className="fas fa-male"></i> {row.owner__username} </div>
    },
    {
        name: "Tags",
        cell: row => <div></div>,

        sortable: true
    }
];
const customStyles = {
    rows: {
      style: {
        minHeight: '72px',
        width:'100%'

       
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px', 
      },
    },
    content : {
        top                   :'0',
        left                  : '0',
        right                 : '0',
        height                : '100%', 
        padding               : '0',
      }
   
  };

const TableView = ({data}) => {
    return (
        <DataTable
        columns={columns}
        data={data}
        defaultSortField="title"
        selectableRows
        customStyles={customStyles}
    />
    )
}

export default TableView