import React from 'react'

const Fillter = () => {
    return (
        <div className="filter-icon">
            <button type="button" className="fas fa-sliders-h" id="dropdownMenuOffset" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="10,20">

            </button>
        
            <div className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
                <div className="card">
                    <div className="card-header">
                        <span>Fillter</span>

                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item  "> your datasets</li>
                        <li className="list-group-item ">Favorited (0)</li>
                        <li className="list-group-item ">locked (0)</li>
                        <li className="list-group-item ">locked (0)</li>
                        <li className="list-group-item active-link ">
                            <ul className="collect-elements">
                                <li className="">public</li> |
                                <li className="">private</li> |
                                <li className="">link</li> |
                                <li className="">password</li>
                            </ul>

                        </li>
                    </ul>
                </div>
            </div>

         </div>
        
    )
}

export default Fillter