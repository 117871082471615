import React from "react";
import Moment from "react-moment";
import {Link} from 'react-router-dom'

const GridView = ({ item }) => {
  return (
    <>
      <div className="card mb-3 grid-card">
        <div className="row no-gutters">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <img src={item.thumbnail_url} className="card-img-top" alt="" />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6">
            <div className="card-body">
              <h5 className="card-title"> <Link to={`/maps/${item.id}`}> {item.title} </Link></h5>
              <span className="text-muted">
                {item.supplemental_information}
              </span>
              <ul className="description-links">
                <li>
                  <i className="fas fa-lock"></i> Private
                </li>
                <li>
                  <i className="fas fa-calendar-alt"></i> Updated{" "}
                  <Moment fromNow>{item.date}</Moment>
                </li>
                <li>
                  <i className="fas fa-tag"></i> No tags
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GridView;