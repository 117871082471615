import axios from "axios"
import {urls} from './urls'
const mapsUrl = urls.mapsListApi;

export const fetchExchangeMaps = async (params) => {
    const result = await axios.get(mapsUrl, { params });
  
    return {
      maps: result.data.objects,
      meta: result.data.meta,
    };
  };